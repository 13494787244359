<template>
	<div class="grid">
		<div class="col-9">
			<div class="card">
                <h3>Mensaje</h3>
                <Textarea v-model="model.sMailBody"  cols="100" rows="10"/>
                <Button label="Guardar" @click="saveCampaign" class="p-button-rounded mr-2 mb-2"></Button>
                <Button label="Regresar" @click="returnMain" class="p-button-rounded mr-2 mb-2"> </Button>
            </div>
        </div>
        <div class="col-3">
              
            <div class="card">
                <div class="formgrid grid">
                    <div class="field col">
                        <label for="price">Asunto</label>
                        <InputText id="name" type="text" v-model="model.sSubject"/>
                    </div>
                </div>
                <div class="formgrid grid">
                    <div class="field col">
                        <label for="price">Lista de Clientes</label>
                        <Dropdown disabled v-model="model.idSegmentType" :options="segments" optionValue="idOption" optionLabel="sOption" placeholder="Elige un Segmento" ></Dropdown>
                    </div>
                    <div v-if="idSegment==3" class="field col">
                        <Textarea v-model="model.sMailList" cols="25" rows="10"  />
                    </div>
                </div>
            </div>            
        </div>
	</div>

</template>
<script>
/* eslint-disable */
export default {
    props: ['idAdvertisingCampaign'],
	data() {
		return {
            segments: null,
            model: {
                sMailBody: '',
                bCorrect: false,
                idSegmentType: 0, 
                sMailList: '',
                sSubject: ''
            }
		}
	},
	created() {
	},
	async mounted() {
		this.isTokenExpired()
        await this.getData()
        await this.getSegments()
    },
	methods: {
        async getData(){
            await axios.get(apiURL + 'AdvertisingCampaign/findAdvertisingCampaign?idAdvertisingCamping=' + this.idAdvertisingCampaign, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
            .then( (response) => {
                this.model = response.data.advertisingCampingData
            } )
            .catch( (error)  => {
                console.log("error")
            } )     
            console.log(this.campaignData)        
        },
		async saveCampaign(){
            if(this.idSegment == 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Debe elegir un segemento',
                    footer: ''
                })
                return
            }

            if(this.sSubject == ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'El asunto es requerido',
                    footer: ''
                })
                return
            }

            if(this.idSegment == 3 && this.sMailList == ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Debe especificar al menos un correo',
                    footer: ''
                })
                return
            }

            if(this.sHtml == ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'El correo no puede ir vacío',
                    footer: ''
                })
                return
            }

            let modelSend = {
                idAdvertisingCampaign: this.idAdvertisingCampaign,
                sSubject: this.model.sSubject,
                sMailBody: this.model.sMailBody
            }

            this.openLoading('Guardando Datos...')
            await axios.post(apiURL + 'AdvertisingCampaign/editAdvertisingCampaign', modelSend, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
            .then( (response) => {
                Swal.close()
                if(response.data.bCorrect == false){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: response.data.sErrors[0],
                        footer: ''
                    })						
                    return
                }
                else{
                    Swal.fire('Campaña guardada correctamente')
                    router.push('/advertisingCampaign')
                }
            } )
            .catch( (error)  => {
                Swal.close()
                console.log("error")
            } )
            
        },
        async getSegments(){
            await axios.get(apiURL + 'Customers/getSegments', { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                } })
            .then( (response) => {
                this.segments = response.data.options
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },
        returnMain(){
            router.push('/advertisingCampaign')
        }

	}
}
</script>

<style scoped lang="scss">

</style>
